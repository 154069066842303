import React from "react";
import _ from "lodash";
import { graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import { MAIN_COLOR, SITE } from "../config";
import Carousel from "nuka-carousel/lib/carousel";
import Icon from "@shared/components/Icon";

const Container = styled.div`
  ${tw`p-5`}
`;

const ContentContainer = styled.div`
  ${tw`p-4`}
`;

const TitleContainer = styled.div`
  ${tw`flex items-center mt-5`}
`;

const Body = styled.div`
  ${tw`prose max-w-full`}

  iframe {
    ${tw`w-full`}
  }
`;

const SliderContainer = styled.div`
  ${tw`my-8 lg:mt-0`}
`;
const LeftButton = styled.button`
  ${tw`absolute left-0 w-9 h-9 text-gray-300`}
  & > .material-icons {
    ${tw`text-4xl`}
  }
`;
const RightButton = styled.button`
  ${tw`absolute w-9 h-9 right-0 text-gray-300`}
  & > .material-icons {
    ${tw`text-4xl`}
  }
`;
const BottomPagingContainer = styled.div`
  ${tw`absolute bottom-[-20px] left-0 right-0 flex justify-center`}
`;
const PagingButton = styled.div`
  ${tw`block w-5 h-1.5 mx-2.5 rounded cursor-pointer`}
  background-color: ${({ isCurrentSlide }) =>
    isCurrentSlide ? tw`bg-[#EC1F27]` : "#bdbdbd"};
`;

const SliderItem = styled.div`
  ${tw`relative mx-4 mb-2`}
`;
const ImageContainer = styled.div`
  ${tw`h-[40px] w-[40px] rounded-full overflow-hidden`}
`;

const AvatarText = styled.div`
  ${tw`ml-4 text-sm text-gray-500`}
`;

const Quote = styled.div`
  ${tw`text-gray-400 italic border-b border-gray-300 pb-4`}
`;

const TestimonialPage = ({ data, location }) => {
  const { seo, hero, tabs, title, testimonialList } =
    data.markdownRemark.frontmatter;
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <SliderContainer>
          <Body
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
          {!_.isEmpty(testimonialList) && (
            <Carousel
              autoplay={true}
              autoplayInterval={5000}
              wrapAround={true} //loops back to first slide when it reaches the end
              slidesToShow={1}
              renderBottomCenterControls={({
                goToSlide,
                currentSlide,
                pagingDotsIndices,
              }) => {
                return (
                  <BottomPagingContainer>
                    {pagingDotsIndices.map((indice) => (
                      <PagingButton
                        key={indice}
                        isCurrentSlide={indice === currentSlide}
                        onClick={() => goToSlide(indice)}
                      />
                    ))}
                  </BottomPagingContainer>
                );
              }}
              renderCenterLeftControls={({ previousSlide }) => (
                <LeftButton onClick={previousSlide}>
                  <Icon name="chevron_left" />
                </LeftButton>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <RightButton onClick={nextSlide}>
                  <Icon name="chevron_right" />
                </RightButton>
              )}
              slideIndex={0}
            >
              {testimonialList.map((item, idx) => {
                const { title, avatar, description } = item;
                return (
                  <SliderItem key={idx}>
                    <ContentContainer>
                      <Quote>{description}</Quote>
                      <TitleContainer>
                        <ImageContainer>
                          <GatsbyImage
                            image={getImage(avatar)}
                            alt="testimonial_avatar"
                            style={{ width: "100%", height: "100%" }}
                            imgStyle={{ width: "100%", height: "100%" }}
                            objectFit="cover"
                            objectPosition="50% 50%"
                          />
                        </ImageContainer>
                        <AvatarText>{title}</AvatarText>
                      </TitleContainer>
                    </ContentContainer>
                  </SliderItem>
                );
              })}
            </Carousel>
          )}
        </SliderContainer>
      </Container>
    </Layout>
  );
};

export default TestimonialPage;

export const pageQuery = graphql`
  query TestimonialPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "testimonial-page" } }) {
      html
      frontmatter {
        seo {
          title
          description
        }
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        title
        testimonialList {
          avatar {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          title
          description
        }
      }
    }
  }
`;
